import React from "react"
import Card from "src/layout/Card";
import Page from "src/layout/Page";
import { dateToDayName } from "src/utils/Util";
import { useEmployeeSummary } from "src/utils/api/employees/Employees";
import styled from "styled-components";

export default function EmployeeReportCardPage( props: any ) {
	let id = props.match.params.id;
    const searchParams = Object.fromEntries(window.location.search.substring(1).split('&').map((e: string) => e.split('=')));

    const [ startState, setStartState ] = React.useState(searchParams.start || new Date(`
        ${new Date().getFullYear()}-${new Date().getMonth()}-01
    `).toLocaleDateString('sv-SE'));
    const [ endState, setEndState ] = React.useState(searchParams.end || new Date(`
        ${new Date().getFullYear()}-${new Date().getMonth()}-${new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()}
    `).toLocaleDateString('sv-SE'));


    const { summary } = useEmployeeSummary(Number(id), {
        start: startState,
        end: endState
    });

    if (!summary) {
        return null;
    }

    const totalAbsence = summary.absenceHours_other + summary.absenceHours_sickLeave + summary.absenceHours_vacation + summary.absenceHours_vab + summary.absenceHours_parentalLeave;

    const totalExpenses = summary.days.reduce((acc, day) => acc + day.expenses.reduce((acc, e) => acc + e.amount, 0), 0);

    return (
        <Style>
            
			<Card size="medium" className="hide-print">
				<h2>Löneunderlag</h2>
				<h5>(denna ruta visas inte vid utskrift)</h5>
				<div className="spacer s"></div>
				<p>Denna sida kan skrivas ut direkt i webbläsaren, antingen som en PDF eller via skrivare.</p>
				<div className="spacer s"></div>

                <label>Från</label>
                <br />
                <input type="date" value={startState} onChange={e => setStartState(e.target.value)} />

                <div className="spacer xs"></div>

                <label>Till</label>
                <br />
                <input type="date" value={endState} onChange={e => setEndState(e.target.value)} />

				<div className="spacer s"></div>
				<button onClick={() => window.print()}>Skriv ut</button>
			</Card>

            <Card size="full" className="printed-report">
                <h1>Löneunderlag för {summary.employee.firstName} {summary.employee.lastName} (anställningsnummer: {summary.employee.id})</h1>
                <div className="spacer m"></div>
                {summary.hasPendingEvents && (<>
                    <p className="has-pending-warning">OBS! Den valda tidsperioden innehåller dagar som ej blivit attesterade! Dessa bör attesteras innan underlaget används för lön!</p>
                    <div className="spacer m"></div>
                </>)}
                <h2 className="date-span">{new Date(startState).toLocaleDateString('sv-SE')} - {new Date(endState).toLocaleDateString('sv-SE')}</h2>
                <table className="time-table" cellSpacing={0}>
                    <thead>
                        <tr>
                            <td>Datum</td>
                            <td>Frånvaro</td>
                            <td>Arbetad tid</td>
                            <td>Tidbank</td>
                            <td>Utlägg</td>
                        </tr>
                    </thead>
                    <tbody>
                        {summary.days.map((day, i) => {

                            const date = new Date(day.date);

                            const totalAbsence = day.absenceHours_other + day.absenceHours_sickLeave + day.absenceHours_vacation + day.absenceHours_vab + day.absenceHours_parentalLeave + day.absenceHours_unpaidLeave;
                            const reportedHours = day.reportedHours - totalAbsence;
                            let difference = reportedHours - day.scheduledHours;

                            // Negative difference is only allowed if there is absence
                            if (totalAbsence > 0) {
                                difference = Math.min(0, difference + totalAbsence);
                            }

                            return (
                                <tr key={i} className={`dow-${date.getDay()} ${day.hasPendingEvents ? 'has-pending' : ''}`}>
                                    <td className="date">{date.getDate()} {dateToDayName(date)?.substring(0, 3)}</td>
                                    <td className="absence">
                                        {day.absenceHours_other !== 0 ? <span>Annan frånvaro: {day.absenceHours_other.toFixed(2)}</span> : ''}
                                        {day.absenceHours_sickLeave !== 0 ? <span>Sjuk: {day.absenceHours_sickLeave.toFixed(2)}</span> : ''}
                                        {day.absenceHours_vacation !== 0 ? <span>Semester: {day.absenceHours_vacation.toFixed(2)}</span> : ''}
                                        {day.absenceHours_vab !== 0 ? <span>Vård av barn: {day.absenceHours_vab.toFixed(2)}</span> : ''}
                                        {day.absenceHours_unpaidLeave !== 0 ? <span>Obetald ledighet: {day.absenceHours_unpaidLeave.toFixed(2)}</span> : ''}
                                        {day.absenceHours_parentalLeave !== 0 ? <span>Föräldraledig: {day.absenceHours_parentalLeave.toFixed(2)}</span> : ''}
                                    </td>
                                    <td>{reportedHours !== 0 ? reportedHours.toFixed(2) : ''}</td>
                                    <td>{difference !== 0 ? difference.toFixed(2) : ''}</td>
                                    <td dangerouslySetInnerHTML={{__html: day.expenses.map(e => `${e.description} <b>${e.amount} kr</b>`).join('\n')}}></td>
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Totalt</td>
                            <td>{totalAbsence.toFixed(2)}</td>
                            <td>{summary.reportedHours.toFixed(2)}</td>
                            <td>{(summary.reportedHours - summary.scheduledHours).toFixed(2)}</td>
                            <td>{totalExpenses.toFixed(2)} kr</td>
                        </tr>
                    </tfoot>
                </table>

            </Card>
        </Style>
    )
}

const Style = styled(Page)`

    .printed-report {
        * {
            font-family: Arial, Helvetica, sans-serif !important;
        }

        .has-pending-warning {
            color: red;
            font-size: 1.2em;
            max-width: 400px;
        }

        table.time-table {
            width: 100%;
            margin-top: 10px;
        }

        table {
            border-top: solid 1px #e0e0e0;
            border-left: solid 1px #e0e0e0;

            font-size: 12px;

            thead {
                tr {
                    td {
                        font-weight: bold;
                        padding: 10px;
                        background: #f0f0f0;
                        color: #424242;
                        text-align: right;
                        border-bottom: solid 1px #e0e0e0;
                        border-right: solid 1px #e0e0e0;
                        
                        &:first-of-type {
                            text-align: center;
                            width: 100px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 10px;
                        border-bottom: solid 1px #e0e0e0;
                        border-right: solid 1px #e0e0e0;
                        text-align: right;

                        &.date {
                            text-align: left;
                            text-transform: uppercase;
                        }

                        &.absence {
                            span {
                                display: block;
                                
                                &:not(:first-of-type) {
                                    margin-top: 2px;
                                }
                            }
                        }
                    }

                    &.dow-0,
                    &.dow-6 {
                        background: #eeeeee;

                        &.dow-0 {
                            td.date {
                                color: #ff0000;
                            }
                        }
                    }

                    &.has-pending {
                        background: #ffebeb;
                    }
                }
            }

            tfoot {
                tr {
                    td {
                        padding: 10px;
                        border-right: solid 1px #e0e0e0;
                        border-bottom: solid 1px #e0e0e0;
                        background: #f0f0f0;
                        text-align: right;
                        font-weight: bold;
                    }
                }
            }
        }
    }
`