import { Event } from 'src/utils/api/employees/Events';
import API from '../API';
import { useEffect, useState } from 'react';

export type Employee = {
	id: number,
	firstName: string,
	lastName: string,
	workingHours: {
		daysOfWeek: number[],
		startTime: string,
		endTime: string,
		breakMinutes: number
	}[]
};

export default class Employees {
	static async list(): Promise<Employee[] | null>{
		const resp = await API.get(`/v2/employees`);
		if (resp.success !== true) return null;
		return resp.employees;
	}

	static async get(id: number): Promise<Employee | null>{
		const resp = await API.get(`/v2/employees/${id}`);
		if (resp.success !== true) return null;
		return resp.employee;
	}

    static async getEvents(id: number, args?: {
		start: Date,
		end: Date,
	}): Promise<Event[]> {
		let url = `/v2/employees/${id}/events`;
		if (args) {
			url += `?`;
			if (args.start) {
				url += `start=${args.start.toISOString()}&`;
			}
			if (args.end) {
				url += `end=${args.end.toISOString()}&`;
			}

			url = url.slice(0, -1);
		}
		const resp = await API.get(url);
		if (resp.success !== true) return [];
		return resp.events.map((e: any) => {
			return {
				...e,
				start: new Date(e.start),
				end: new Date(e.end),
			};
		});
	}

	static async getSummary(id: number, args?: {
		year: number,
		month: number,
		start?: string,
		end?: string,
	}) {

		let url = `/v2/employees/${id}/summary`;
		if (args) {
			url += '?';
			if (args.year) {
				url += `year=${args.year}&`;
			}

			if (args.month) {
				url += `month=${args.month}&`;
			}

			if (args.start) {
				url += `start=${args.start}&`;
			}

			if (args.end) {
				url += `end=${args.end}&`;
			}

			url = url.slice(0, -1);
		}

		const resp = await API.get(url);
		if (resp.success !== true) return null;
		return resp.data;
	}

	static async getStats(id: number): Promise<EmployeeStats[] | null> {
		const resp = await API.get(`/v2/employees/${id}/stats`);
		if (resp.success !== true) return null;
		return resp.stats.map((s: any) => {
			return {
				...s,
				created_at: new Date(s.created_at),
			};
		});
	}
}

export function getWorkingHours(employee: Employee, date: Date) {
	const workingHours = employee.workingHours.find(wh => wh.daysOfWeek.includes(date.getDay()));
	if (!workingHours) return null;
	return workingHours;
}

export function getWorkingHoursDuration(employee: Employee, date: Date) {
	const workingHours = getWorkingHours(employee, date);
	if (!workingHours) return 0;
	const start = new Date(date);
	const end = new Date(date);
	const [hours, minutes] = workingHours.startTime.split(':').map(Number);
	start.setHours(hours, minutes);
	const [endHours, endMinutes] = workingHours.endTime.split(':').map(Number);
	end.setHours(endHours, endMinutes);
	return (end.getTime() - start.getTime()) / 1000 / 60 - workingHours.breakMinutes;
}

export function useEmployeeSummary(id: number | null, args?: {
	year?: number,
	month?: number,
	start?: string,
	end?: string,
}) {
	const [summary, setSummary] = useState<null | EmployeeSummary>(null);
	useEffect(() => {
		if (id) {
			Employees.getSummary(id, {
				year: args?.year || new Date().getFullYear(),
				month: args?.month || new Date().getMonth() + 1,
				start: args?.start,
				end: args?.end,
			}).then(setSummary);
		}
	}, [id, args?.month, args?.year, args?.start, args?.end]);

	return {summary};
}

export type EmployeeSummary = {
	employee: Employee,
	scheduledHours: number,
	reportedHours: number,
	billableHours: number,
	nonBillableHours: number,
	breakHours: number,
	absenceHours_parentalLeave: number,
	absenceHours_sickLeave: number,
	absenceHours_vacation: number,
	absenceHours_vab: number,
	absenceHours_unpaidLeave: number,
	absenceHours_other: number,
	hasPendingEvents: boolean,
	days: {
		date: string,
		scheduledHours: number,
		reportedHours: number,
		billableHours: number,
		nonBillableHours: number,
		breakHours: number,
		absenceHours_parentalLeave: number,
		absenceHours_sickLeave: number,
		absenceHours_vacation: number,
		absenceHours_vab: number,
		absenceHours_unpaidLeave: number,
		absenceHours_other: number,
		hasPendingEvents: boolean,
		expenses: {
			id: number,
			amount: number,
			description: string,
		}[],
	}[]
}

export function useEmployeeStats(id: number | null) {
	const [stats, setStats] = useState<null | EmployeeStats[]>(null);
	useEffect(() => {
		if (id) {
			Employees.getStats(id).then(setStats);
		}
	}, [id]);

	return {stats};
}

export type EmployeeStats = {
	id: number,
	name: string,
	data: any,
	created_at: Date,
}
